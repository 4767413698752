import React, { useCallback, useState } from "react";
import { styled } from "goober";
import { Checkbox } from "clutch/src/Checkbox/Checkbox.jsx";

import { readState } from "@/__main__/app-state.mjs";
import type { FeatureFlags } from "@/__main__/feature-flags.mjs";
import { allowRead } from "@/__main__/feature-flags.mjs";
import { setFeatureFlag } from "@/app/actions.mjs";
import { GameSharkSection } from "@/feature-gameshark/GameSharkSection.jsx";

export function GameSharkFeaturesSection() {
  const featureFlags = Object.keys(readState.features) as Array<
    keyof FeatureFlags
  >;

  return (
    <GameSharkSection title="Features">
      <ToggleList>
        {featureFlags.map((flag) => (
          <ToggleFeature key={flag} flag={flag} />
        ))}
      </ToggleList>
    </GameSharkSection>
  );
}

const ToggleList = styled("div")`
  display: grid;
  grid-template-columns: repeat(auto-fill, 150px);
  gap: var(--sp-2);

  min-width: 400px;
`;

function ToggleFeature({ flag }: { flag: keyof FeatureFlags }) {
  const [isEnabled, setIsEnabled] = useState<boolean>(() => {
    // Could doing this here allow other components to read feature flags on the frame that
    // this component is mounted?
    allowRead();

    return readState.features[flag];
  });

  const [status, setStatus] = useState<"loading" | "failure" | null>(null);

  const toggleFlag = useCallback(
    async (isEnabled: boolean) => {
      try {
        setStatus("loading");

        await setFeatureFlag(flag, isEnabled);

        setStatus(null);
      } catch (err) {
        setStatus("failure");
      }

      allowRead();
      setIsEnabled(readState.features[flag]);
    },
    [flag],
  );

  return (
    <Checkbox
      checked={isEnabled}
      onChange={toggleFlag}
      disabled={status === "loading"}
      error={status === "failure" && "An error occurred"}
    >
      {flag}
    </Checkbox>
  );
}
