import type React from "react";

import { proxy } from "@/util/proxy.mjs";

type GamesharkRefs = {
  CryptoSection: React.FC;
};

export const gamesharkRefs = proxy<GamesharkRefs>({
  CryptoSection: null,
});
