import React, { useCallback } from "react";
import { styled } from "goober";
import { Button } from "clutch/src/Button/Button.jsx";

import { IS_STAGING } from "@/__main__/constants.mjs";
import { GameSharkFeaturesSection } from "@/feature-gameshark/FeaturesSection.jsx";
import { gamesharkRefs } from "@/feature-gameshark/refs.mjs";
import Minimize from "@/inline-assets/minimize.svg";
import { useClientOnly } from "@/util/ClientOnly.jsx";
import globals from "@/util/global-whitelist.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function GameSharkOverlay() {
  const { CryptoSection } = useSnapshot(gamesharkRefs);

  const [show, setShow] = React.useState(false);

  const toggleStagingEnv = useCallback(() => {
    if (IS_STAGING) {
      globals.localStorage.removeItem("env");
    } else {
      globals.localStorage.setItem("env", "staging");
    }

    globals.location.reload();
  }, []);

  if (!show) {
    return (
      <OverlayContainer>
        <MinimizedTitle onClick={() => setShow(true)} />
      </OverlayContainer>
    );
  }

  return (
    <OverlayContainer>
      <div className="content">
        <GameSharkFeaturesSection />

        {CryptoSection && <CryptoSection />}

        <EnvSwitcherSection>
          <Button onClick={toggleStagingEnv}>
            {"Switch to " + (IS_STAGING ? "Production" : "Staging")}
          </Button>
        </EnvSwitcherSection>
      </div>

      <MaximizedTitle onClick={() => setShow(false)} />
    </OverlayContainer>
  );
}

function MinimizedTitle({
  onClick,
}: {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}) {
  const isHydrated = useClientOnly();

  return (
    <footer>
      <button className="toggle-visible type-caption--semi" onClick={onClick}>
        {"🦈"}
        {isHydrated && IS_STAGING && (
          <span className="warn type-mini">{"Staging"}</span>
        )}
      </button>
    </footer>
  );
}

function MaximizedTitle({
  onClick,
}: {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}) {
  return (
    <footer>
      <button className="toggle-visible type-caption--semi" onClick={onClick}>
        {"🦈"}
        <span className="type-mini">{"Cheat Menu"}</span>
        <Minimize />
      </button>
    </footer>
  );
}

const OverlayContainer = styled("div")`
  position: fixed;
  left: 0;
  bottom: 0;

  color: var(--shade3);

  background: var(--shade7);
  box-shadow: 0 0 10px black;
  outline: 1px solid var(--shade5);
  border-top-right-radius: var(--br);

  z-index: 998;

  .toggle-visible {
    all: unset;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--sp-2);

    width: 100%;

    color: var(--shade2);

    cursor: pointer;
    user-select: none;

    box-sizing: border-box;

    svg {
      width: var(--sp-4);
      height: var(--sp-4);
    }

    span {
      padding-right: var(--sp-3);

      &.warn {
        color: var(--yellow);
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: var(--sp-2);

    max-height: 80dvh;
    padding: var(--sp-2);

    overflow: auto;
  }
`;

const EnvSwitcherSection = styled("section")`
  & > button {
    width: 100%;
  }
`;
