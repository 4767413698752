import {
  __ONLY_WRITE_STATE_FROM_ACTIONS as writeState,
  isPersistent,
  readState,
} from "@/__main__/app-state.mjs";
import { MAX_TIME } from "@/__main__/constants.mjs";
import { oauthTokenHandler } from "@/game-destiny2/utils/auth-token-handler.mjs";
import type { BungieToken } from "@/game-destiny2/utils/token-model.mjs";
import { appError, appLog } from "@/util/dev.mjs";

// Destiny 2
const isBungieToken = (token): token is BungieToken => {
  if (token.bungie_uid && token.expires_at && token.has_expiry && token.token)
    return true;
  throw new Error(`"${token}" is not a valid bungie token`);
};

const exportAuthTokens = () => appLog(JSON.stringify(readState.destiny2.oauth));

const importAuthTokens = (tokens) => {
  if (typeof tokens === "string") tokens = JSON.parse(tokens);
  for (const [bungieId, token] of Object.entries(tokens)) {
    try {
      if (isBungieToken(token)) oauthTokenHandler.setToken(bungieId, token);
    } catch (e) {
      appError(`Error importing token for "${bungieId}"`, e);
    }
  }
};

export const destiny2 = {
  exportAuthTokens,
  importAuthTokens,
  showInfoModal: () => {
    const hold = { show: true };
    hold[isPersistent] = MAX_TIME;
    writeState.destiny2.showInfoModal = hold;
  },
  oauthTokenHandler,
};
