import mainRefs from "@/__main__/refs.mjs";
import { destiny2 } from "@/feature-gameshark/console-utils.mjs";
import GameSharkOverlay from "@/feature-gameshark/GameSharkOverlay.jsx";
import globals from "@/util/global-whitelist.mjs";
import mapOriginalRefs from "@/util/map-original-refs.mjs";

const original = mapOriginalRefs({
  mainRefsComponents: mainRefs.components,
});

export function setup() {
  original.append({
    mainRefsComponents: {
      floatingElementsTop: [GameSharkOverlay],
    },
  });

  if (globals.__BLITZ_DEV__) globals.__BLITZ_DEV__.destiny2 = destiny2;
}

export function teardown() {
  original.restore();

  if (globals.__BLITZ_DEV__) delete globals.__BLITZ_DEV__.destiny2;
}
