import React from "react";
import { styled } from "goober";

export function GameSharkSection({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) {
  return (
    <SectionContainer>
      <label>
        <input type="checkbox" className="visually-hidden" />
        <h1 className="type-mini">{title}</h1>
      </label>
      <div className="container">{children}</div>
    </SectionContainer>
  );
}

export const SectionContainer = styled("section")`
  display: flex;
  flex-direction: column;
  gap: var(--sp-2);

  padding: var(--sp-1_5);

  background-color: hsla(var(--shade4-hsl) / 0.2);
  border: 1px solid var(--shade5);
  border-radius: var(--br);

  & > h1 {
    padding: 0 var(--sp-2);

    color: var(--shade2);
  }

  & > label {
    cursor: pointer;
  }

  & > label:not(:has(input:checked)) + .container {
    display: none;
  }
`;
